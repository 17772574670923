import React from 'react';
import axios from 'axios';

import logo from '../../../assets/images/logo_servier_new.png';

import { ReactComponent as Facebook } from '../../../assets/icons/facebook-logo.svg';
import { ReactComponent as Youtube } from '../../../assets/icons/youtube.svg';
import { ReactComponent as Linkedin } from '../../../assets/icons/linkedin.svg';

// styles
import {
  Wrapper,
  LinkTo,
  Contact,
  Social,
  IconItem,
  FooterMainWrapper,
  FooterMain,
  Instructions,
  LogoImg,
  FooterCenteredText,
} from './styles';

// api breakpoint
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'api/v1/translations';

export function Footer() {
  // fetch data from api
  const [translation, setTranslation] = React.useState({
    footer_phone: '',
    footer_fax: '',
    footer_phone_1: '',
    footer_phone_2: '',
    footer_email_1: '',
    footer_email_2: '',
    footer_name: '',
    footer_description: '',
    footer_address_1: '',
    footer_address_2: '',
  });

  const fetchTranslation = () => {
    axios
      .get(`${baseUrl}`)
      .then(response => {
        const translated = response.data;

        setTranslation(translated);
      })
      .catch(error => console.error(`Error: ${error}`));
  };

  React.useEffect(() => {
    fetchTranslation();
  }, []);

  const { footer_address_1 } = translation;

  return (
    <Wrapper>
      <FooterMainWrapper>
        <FooterMain>
          <LogoImg src={logo} />

          <LinkTo href={process.env.PUBLIC_URL + '/rules.pdf'} target="_blank">
            Угода користувача
          </LinkTo>

          <LinkTo
            href={process.env.PUBLIC_URL + '/cookie-policy.pdf'}
            target="_blank"
          >
            Політика використання cookie
          </LinkTo>

          <LinkTo
            href="https://servier.ua/kontakty/#%d0%bf%d0%be%d0%b2%d1%96%d0%b4%d0%be%d0%bc%d0%bb%d0%b5%d0%bd%d0%bd%d1%8f-%d0%bf%d1%80%d0%be-%d0%bf%d0%be%d0%b1%d1%96%d1%87%d0%bd%d0%b5-%d1%8f%d0%b2%d0%b8%d1%89%d0%b5-%d0%bd%d0%b5%d0%b1%d0%b0%d0%b6"
            target="_blank"
          >
            Повідомити побічну реакцію
          </LinkTo>

          <LinkTo href="https://servier.ua/ukr/contact" target="_blank">
            Форми для зв'язку
          </LinkTo>

          <Social>
            <Contact
              href={`https://www.facebook.com/servier.urkraine`}
              target={'_blank'}
            >
              <IconItem>
                <Facebook width={26} height={26} fill={'#ffffff'} />
              </IconItem>
            </Contact>

            <Contact
              href={`https://www.youtube.com/channel/UCtSrgM1KBRBVbhcLBpzD96w/`}
              target={'_blank'}
            >
              <IconItem>
                <Youtube width={26} height={26} fill={'#ffffff'} />
              </IconItem>
            </Contact>

            <Contact
              href={`https://www.linkedin.com/showcase/servier-ukraine`}
              target={'_blank'}
            >
              <IconItem>
                <Linkedin width={26} height={26} fill={'#ffffff'} />
              </IconItem>
            </Contact>
          </Social>
        </FooterMain>

        <Instructions>
          <LinkTo
            href={
              'https://servier.ua/wp-content/uploads/sites/25/2023/10/IMU_Lonsurf_final_dd-08.02.2024.pdf?time=1713172538'
            }
            target="_blank"
          >
            Інструкція Лансурф®
          </LinkTo>
          <LinkTo
            href={
              'https://servier.ua/wp-content/uploads/sites/25/2023/12/IMU_Onivyde_final.pdf?time=1738942413'
            }
            target="_blank"
          >
            Інструкція Онівайд®
          </LinkTo>
          <LinkTo
            href={
              'https://servier.ua/wp-content/uploads/sites/25/2024/06/IMU_Tibsovo_final.pdf?time=1717671592'
            }
            target="_blank"
          >
            Інструкція Тібсово
          </LinkTo>
        </Instructions>

        <FooterCenteredText
          dangerouslySetInnerHTML={{ __html: footer_address_1 }}
        />
      </FooterMainWrapper>
    </Wrapper>
  );
}
